import React from 'react';
import logo from './bb_kids.webp';
import './App.css';

const BB_MOVIE = {
  "id": null,
  "episode": null,
  "season": null,
  "name": "The Bob's Burgers Movie"
};

const NO_PREV_EP = {
  "id": null
};

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      episode: {},
      previousEpisode: null
    };
  }
  async UNSAFE_componentWillMount() {
    const prevEp = localStorage.getItem("episode")
    this.setState({
      previousEpisode: prevEp != null ? JSON.parse(localStorage.getItem("episode")) : NO_PREV_EP
    })
    const response = await fetch("https://bobsburgers-api.herokuapp.com/episodes/");
    const json = await response.json();
    const randomEpisode = json[Math.floor(Math.random() * (json.length + 1))]
    this.setState({
      episode: this.state.previousEpisode !== null && this.state.previousEpisode.id !== randomEpisode.id ? randomEpisode : BB_MOVIE
    }, () => {
      localStorage.setItem("episode", JSON.stringify(this.state.episode))
    });
  }
  render() {
    // console.log(this.state)
    return (
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <h2>{this.state.episode.name}</h2>
          {this.state.episode.id != null &&
            <h3>Season {this.state.episode.season} Episode {this.state.episode.episode}</h3>
          }
        </header>
      </div>
    );
  }
}

export default App;
